import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "../../mainComponents/button"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Typography, Modal } from "@material-ui/core"

import Cross from "../../../static/svg/cross.svg"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    "@media (max-width: 1024px)": {},
    "@media (max-width: 767px)": {},
  },
  input: {
    border: "1px solid #FFFFFF",
    outline: "none",
    borderRadius: "1000px",
    background: "transparent",
    color: theme.palette.primary.main,
    padding: "0px 30px",
    fontFamily: "Krona One",
    textTransform: "none",
    lineHeight: "100%",

    fontSize: 14,
    width: 402,
    height: 42,
    "@media (max-width: 1024px)": {
      fontSize: "1.4vw",
      width: "calc(45vw - 30px)",
      height: "5vw",
    },
    "@media (max-width: 767px)": {
      fontSize: "2.8vw",
      paddingLeft: "4vw",
      paddingRight: "4vw",
      width: "calc(65vw - 4vw)",
      height: "8vw",
    },
  },
  button: {
    marginTop: 20,
  },
  modal: {
    display: "flex",
    alignItems: "center",
  },
  modalContent: {
    boxSizing: "border-box",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",

    margin: "auto",
    outline: "none",
    background: theme.palette.background.card,
    border: "1px solid rgba(255, 255, 255, 0.2)",

    width: "34.93vw",
    borderRadius: "1.52vw",
    padding: "1.52vw",
    "@media (min-width:1440px)": {
      width: 503,
      borderRadius: 22,
      padding: 22,
    },
    "@media (max-width:767px)": {
      width: "90%",
      borderRadius: "4.51vw",
      padding: "4.51vw",
    },
  },
  modalContentText: {
    width: "100%",

    fontWeight: 700,
    lineHeight: 1.35,
    wordWrap: "break-word",

    fontSize: "1.38vw",
    "@media(min-width: 1440px)": {
      fontSize: 20,
    },
    "@media(max-width: 767px)": {
      fontSize: "4.4vw",
    },
  },

  cross: {
    background: "transparent",
    padding: 0,
    minWidth: 0,
    border: "none",
    cursor: "pointer",

    width: "1.38vw",
    height: "1.38vw",
    "@media (min-width:1440px)": {
      width: 20,
      height: 20,
    },
    "@media (max-width:767px)": {
      width: "4.1vw",
      height: "4.1vw",
    },
  },
}))

export default function FormCard({
  placeholder,
  buttonText,
  afterSuccesSubmit,
  listFields,
}) {
  listFields = listFields ?? { page: "/main" }
  const [email, setEmail] = useState("")
  const [modal, setModal] = useState(null)

  async function handleSubmit(e) {
    e.preventDefault()
    const result = await addToMailchimp(email, listFields)

    //alert with mailchimp response
    // alert(result.msg)

    if (modal?.timeoutId) clearTimeout(modal.timeoutId)

    const timeoutId = setTimeout(() => setModal(null), 5000)
    setModal({ message: result.msg, timeoutId })

    if (result.result === "success" && afterSuccesSubmit) afterSuccesSubmit()
  }

  const classes = useStyles()
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <input
        id="emailForm"
        placeholder={placeholder ?? "enter your email"}
        className={classes.input}
        value={email}
        onChange={e => setEmail(e.target.value)}
      />

      <div className={classes.button}>
        <Button type="submit">{buttonText ?? "Keep me updated"}</Button>
      </div>

      <Modal
        open={modal}
        onClose={() => setModal(null)}
        className={classes.modal}
      >
        <div className={classes.modalContent}>
          <Typography className={classes.modalContentText}>
            {modal?.message}
          </Typography>

          <button onClick={() => setModal(null)} className={classes.cross}>
            <Cross />
          </button>
        </div>
      </Modal>
    </form>
  )
}
