import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
    root: {
        cursor: "pointer",
        border: "none",
        outline: "none",
        display: 'flex',
        flexDirection:'row',
        alignItems: 'flex-start',
        padding: '10px 140px 10px 17px',
        background: theme.palette.primary.accent,
        '&:hover': {
            background: theme.palette.primary.accent,
        },
        borderRadius: '1000px',
        '@media (max-width: 1024px)': {
            padding: '1.5vw 11vw 1.5vw 1.5vw',
        },
        '@media (max-width: 767px)': {
            padding: '2.5vw 20vw 2.5vw 4vw',
        },

    },
    title: { 
        color: "#000",
        fontSize: 14,
        fontFamily: 'Krona One',
        textTransform: 'none',
        lineHeight: '1 !important',
        '@media (max-width: 1024px)': {
            fontSize: '1.4vw',
        },
        '@media (max-width: 767px)': {
            fontSize: '2.8vw',
        },
    },
}))


export default function GreenButton ({children}) {

    const classes = useStyles();
    
      return (
        <button className={classes.root} aria-label="Form Button">
            <Typography className={classes.title}>
                {children}
            </Typography>
        </button>
      )
    }